<template>
  <div>
    <Head :title="$t('layout.Community')"></Head>
    <!-- 背景 -->
    <img src="@/image/bg_03.jpg" class="bg" alt="" :style="'width:'+$setWidth">
    <div class="notice" :style="'width:'+$setWidth">
    <div class="flex_lr" style="padding: 10px; color: #FFFFFF; font-size:calc(var(--text) - 2px)">{{$t('myCommunity.title')}}</div>
      <div class="notice_box flex_lr" v-for="(item,index) in list" :key="index">
        <div class="flex_center" style="width:22px;height:22px;border:1px solid #fff;border-radius:50%;font-size: 0.75rem;margin-right: 10px;">{{index+1}}</div>
        <div class="y_center" style="width:0;flex:1;">
          <div class="y_center" style="width: 100%;padding-right:22px;border-bottom:1px solid #ffffff;">
            <div class="notice_title common_text flex_center txt">{{item.wallet.slice(0,3)+'********************'+item.wallet.slice(item.wallet.length-1)}}</div>
            <!-- <i class="ifont icone-fuzhi" @click="copy(item.wallet)" /> -->
          </div>
          <div @click="more(item.wallet,index)" class="hand" style="text-align:center;padding:10px 22px 10px 0;">{{$t('myCommunity.text1')}}</div>
        </div>
      </div>
    </div>
    <div class="calculation flex_center" v-if="show_more">
      <div class="mask" @click="show_more=false"></div>
      <div class="calculation_box y_center">
        <div class="calculation_title flex_row">
          <img src="@/image/jinshan_21.png" alt="">
          <div>MTDAO</div>
        </div>
        <div class="calculation_list flex_lr">
          <div>MT{{$t('myCommunity.text2')}}</div>
          <div>{{Math.ceil(info.power390/2.8)||0}}</div>
        </div>
        <div class="calculation_list flex_lr">
          <div>ULME{{$t('myCommunity.text2')}}</div>
          <div>{{info.power||0}}</div>
        </div>
        <div class="calculation_list flex_lr">
          <div>{{$t('myCommunity.text3')}}</div>
          <div>{{info.powerTeam||0}}</div>
        </div>

      </div>
    </div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';
export default {
  data () {
    return {
      number: 0,
      list:[],
      wallet:'',
      show_more:false,
      info:{},
      loading:''
    }
  },
  computed: {
    wallet_address() {
		return this.$store.state.user.wallet_address
	},
  },
  mounted () {
    this.wallet = this.$store.state.user.wallet_address
    this.init()
    
  },
  methods: {
    init() {
      this.get('/api/Community/direct_drive', {wallet:walletHelper.getAddress()}, 'POST').then( res => {
        this.list = res.list
        console.log(this.list)
      })
    },
    more(address,i){
      var that = this
        console.log(address)
      if(this.list[i].allocated_ulm>0){
        let time=new Date(this.list[i].create_time).getTime()
        time=Math.ceil((new Date().getTime()-time)/86400000)
        time=time*3.9
        that.$set(that.info,'power390',3329-time)
      }else{
        this.openLoading()
        var num = 0
        walletHelper.getContract('daoV1h').methods.getTeamPower(address).call().then(res=>{
          console.log(res)
          that.$set(that.info,'powerTeam',res)
          num++
          if(num==3){
            that.closeLoading()
          }
        })
          let time=Math.ceil(new Date().getTime()/1000)

        walletHelper.getContract('dao390').methods.getUserPower(address,time).call().then(res=>{
          console.log(res)
          that.$set(that.info,'power390',walletHelper.Wei(res))
          num++
          if(num==3){
            that.closeLoading()
          }
        })
        walletHelper.getContract('daoV1').methods.data(address,walletHelper.dataIndex._power).call().then(res=>{
          console.log(res)
          that.$set(that.info,'power',walletHelper.Wei(res))
          num++
          if(num==3){
            that.closeLoading()
          }
        })
      }
      
      this.show_more = true
    },
    openLoading(){
      this.loading =Loading.service({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        });
    },
    closeLoading(){
      this.loading.close()
    },
    copy(text){
      this.$copyText(text).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .bg{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .calculation{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .calculation_box{
      position: relative;
      z-index: 2;
      background-image: url(./../image/jinshan_31.png);
      background-size: cover;
      border-radius: 5px;
      width: 300px;
      padding-bottom: 20px;
      .calculation_title{
        margin: 20px 0;
        color: #ffffff;
        font-weight: bold;
        font-size: 1.2rem;
        img{
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .calculation_list{
        width: 90%;
        margin-bottom: 10px;
        color: #ffffff;
        font-size: 16px;
      }
    }
  }
  .notice{
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    .notice_box{
      background-color: rgba(#1A328B,0.5);
      color: #ffffff;
      border-radius: 10px;
      padding: 0 10px;
      margin-top: 10px;
      .notice_title{
        padding: 10px 0;
        margin-bottom: 5px;
        // border-bottom: 1px solid #1A328B;
      }
      .notice_text{
        padding: 3px 0;
      }
      .txt{
        display:-webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient:vertical;
        overflow:hidden;
      }
    }
  }
</style>